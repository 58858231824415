import React from "react";
import classes from "./styles.module.scss";
import QuestionContainer from "../../container/questionContainer";
import Webcam from "../../components/webcam";

const QuestionPage = () => {
    return(
        <>
            <QuestionContainer />
            {/* <Webcam /> */}
        </>
    )
}

export default QuestionPage;